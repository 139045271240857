import {Request} from '@/http/request'

const obj = {
    "textarea1641434070934": "小区话术\n兼职:您好，是xxx先生/女士吗?我们是XXX装饰公司的\n客:是的(不是-问:您贵姓?然后继续)\n兼职:xx先生/女士您好，是这样的，全友家居疫情期间总部专\n款专项补贴，1.8米大床788元时尚布艺沙发1980元，护脊床垫799\n元，真皮软床2388元，全部定制39800元我们小区和全友达成样板间合作，针对我们小区有特殊的优惠政策，每个户主都可以享受总部的万元补贴，请问您家房子全屋定制做了吗?\n\n-A顾客:没有\n兼职:那太好了!我们这个样板间发布会就是专门针对我们小区这\n类业主开展的，很多优惠都是当天才有的，你到时候是有时间过来\n是吗?\n一A顾客:可以\n\n提问:\n兼职:那好的，您这边大概是需要什么呢，单件还是全套呢?我帮您\n\n备注下。(前面没说就问，说了就不问)顾客:。。。。。\n兼职:请问您到时候大概是几个人到场呢?我们好提前准备",
    "upload1641437477387": [
        "http://223.4.31.93/file/2022-06-14/67048907818222239319.jpg"
    ],
    "upload1654762503933": [
        "http://223.4.31.93/file/2022-06-14/19088592955722362812.doc"
    ],
    "upload1646644440624": [
        "http://223.4.31.93/file/2022-06-14/10461822596311516370.png"
    ],
    "industry": "装修（注册资本300万以上）",
    "upload1652172370134": [
        "http://223.4.31.93/file/2022-06-14/96677952452107879237.jpg"
    ],
    "upload1641437452431": [
        "http://223.4.31.93/file/2022-06-14/01049466034569878744.jpg"
    ],
    "upload1641433199751": [
        "http://223.4.31.93/file/2022-06-14/28727573690732861232.jpg"
    ],
    "upload1641433313809": [
        "http://223.4.31.93/file/2022-06-14/83309184139936792550.jpg"
    ],
    "input1641435617712": "公众号：山西颐和君悦民宿"
}

class RecordService {
    // 获取一段时间内的已读情况
    async voiceCheckProgressChart(params) {
        const res = await Request.axiosInstance.post('/open/voice-check/progress-chart', params)
        return res
    }

    // 语音质检类型下拉列表
    async addBlackA(params) {
        const res = await Request.axiosInstance.post('/open/black-tel-a/add', params)
        return res
    }

    async blackNumberAdd(params) {
        const res = await Request.axiosInstance.post('/open/black-number/add', params)
        return res
    }

    // 获取报备行业
    async reportIndustry(params) {
        // return obj
        const res = await Request.axiosInstance.post('/pvm/api/lineData/query-industry-text', params)
        return res
    }

    // 根据检查id进行查询
    async getByCheckId(params) {
        const res = await Request.axiosInstance.post('/open/voice-check-content/get-by-checkId', params)
        return res
    }

    // 查询用户列表
    async queryList() {
        const res = await Request.axiosInstance.post('/web/user/query')
        return res
    }

    // 获取账号类别 管理员还是用户 web 就是普通用户  system就是管理员用户
    async getUserType() {
        const res = await Request.axiosInstance.post('/system/admin-user/user-type')
        return res
    }

    //用户配置查询
    async getUserOptions() {
        const res = await Request.axiosInstance.post('/open/voice-user-options/query')
        return res
    }

    //系统质检查询页面风险等级调用接口
    async getSystemProgressChart(params) {
        const res = await Request.axiosInstance.post('/open/voice-check/progress-chart', params)
        return res
    }

    //用户质检查询页面增加风险等级调用接口
    async getProgressChart(params) {
        const res = await Request.axiosInstance.post('/open/voice-user-check/progress-chart', params)
        return res
    }

    // 用户语音质检在点击详细内容时,需要调已读接口
    // "id"  # 用户质检id
    async getRead(params) {
        const res = await Request.axiosInstance.post('/open/voice-user-check/read', params)
        return res
    }

    // 用户语音质检在点击详细内容时,需要调已读接口
    // "id"  # 用户质检id
    async getSMMRead(params) {
        const res = await Request.axiosInstance.post('/open/voice-check/read', params)
        return res
    }

    // 增加上一条,下一条接口(系统)
    /* 
        "checkId":1554381236106772481,  # 系统质检id
        "queryType":"0"  # 翻页类型 0: 上一条 1: 下一条(必传)
    */
    async getNextPlus(params) {
        const res = await Request.axiosInstance.post('/open/es-check/next-plus', params)
        return res
    }

    // 增加上一条,下一条接口(系统)
    /* 
        "checkId":1554381236106772481,  # 用户质检id (必传)
        "queryType":"0"  # 翻页类型 0: 上一条 1: 下一条(必传)
    */
    async getNextUser(params) {
        const res = await Request.axiosInstance.post('/open/es-user-check/next', params)
        return res
    }

    // 系统质检审核接口
    /* 
        "id":1554381236106772481,  # 质检记录ID
        "processingMethod":string 处理方法
    */
    async updateVoiceProcessingMethod(params) {
        const res = await Request.axiosInstance.post('/open/voice-check/process', params)
        return res
    }

    // 系统质检审核状态接口
    /*
        "checkId":1554381236106772481,  # 系统质检id (必传)
        "risk":2  # 风险等级0-无/1-低/2-中/3-高
    */
    async updateVoiceRisk(params) {
        const res = await Request.axiosInstance.post('/open//voice-check/update-risk', params)
        return res
    }

    // 系统质检审核状态接口
    /*
        "checkId":1554381236106772481,  # 系统质检id (必传)
        "auditStatus":2  # 审核状态(0-未审核/1-已审核/2-违规/3-误判/4-复审)(必传)
    */
    async getVoiceCheck(params) {
        const res = await Request.axiosInstance.post('/open/voice-check/set-audit-status', params)
        return res
    }

    // 用户质检审核状态接口
    /* 
        "checkId":1554382657992605698,  # 系统质检id (必传)
        "auditStatus":2  # 审核状态(0-未审核/1-已审核/2-违规/3-误判/4-复审)(必传)
    */
    async getVoiceUserCheck(params) {
        const res = await Request.axiosInstance.post('/open/voice-user-check/set-audit-status', params)
        return res
    }

    // 用户查看用户质检统计和管理员查看用户质检统计接口对接(接口共用,仅传参和字段展示不同
    async getTotalCount() {
        const res = await Request.axiosInstance.post('/open/voice-count/total-count')
        return res.data
    }

    // 用户页面存在用户价格查询
    // "uid":100008           # 用户id
    async getByUid(params) {
        const res = await Request.axiosInstance.post('/open/voice-user-price/get-by-uid', params)
        return res
    }

    // 命中高风险数量查询
    async getHotRisk() {
        const res = await Request.axiosInstance.post('/open/voice-count/get-hot-risk')
        return res
    }

    // 列表查询
    /* 
        "dateEnd":"2022-07-29",  # 结束时间(必传)
        "dateBegin":"2022-07-01", # 开始时间(必传)
        "uid":100008,             # 用户id
        "amountId":"9",           # 服务商id
        "sortBy":["check_total"],  # 排序字段
        "sortDesc":[false]         # 排序规则
    */
    async getCheckCount(params) {
        const res = await Request.axiosInstance.post('/open/voice-count/check-count', params)
        return res.data
    }

    // 系统质检类型list
    /* 
        hitTypeStatus 是否命中
        risk: 风险等级(0-无/1-低/2-中/3-高)
        auditStatus:  审核状态(0-未审核/1-已审核/2-违规/3-误判/4-复审)
        businessType: 行业(通过行业查询下拉接口获取行业id)
        主叫号码 telA 企业号码 telX 被叫号码 telB
        创建时间 createTimeBegin createTimeEnd 风险等级 risk
        命中关键词 keyword 质检类型 hitType
        用户 uid 服务商 amountId 接口类型 interfaceId
        通话时间 durationBegin durationEnd
    */
    async getCheckList(params) {
        const res = await Request.axiosInstance.post('open/es-check/list', params)
        return res.data
    }

    // 用户质检类型list
    async getUserCheckList(params) {
        const res = await Request.axiosInstance.post('/open/es-user-check/list', params)
        return res.data
    }
    // 线路质检详情
    async getRecordDetail(params) {
        //params:{id:'id'}
        const res = await Request.axiosInstance.post('/open/es-check/get', params)
        return res
    }

    // 查询通信账户集合
    async getAmountList() {
        const res = await Request.axiosInstance.post('/open/amount/query-name')
        return res
    }

    // 语音质检类型下拉列表
    async getVoiceName() {
        const res = await Request.axiosInstance.post('/open/voice-type/query-name')
        return res
    }
     // 根据key查询
     async scriptQuery() {
        const res = await Request.axiosInstance.post('/open/voice-api-script/get-by-key')
        return res
    }

}

class RuleService {
    async getList(params) {
        const res = await Request.axiosInstance.post('/open/voice-type/list', params)
        return res.data
    }

    async queryByIndustry(params) {
        //根据行业id查询关键词信息
        //{industryId}
        const res = await Request.axiosInstance.post('/open/voice-type/query', params)
        return res
    }

    async created(params) {
        const res = await Request.axiosInstance.post('/open/voice-type/add', params)
        return res
    }

    async update(params) {
        const res = await Request.axiosInstance.post('/open/voice-type/edit', params)
        return res
    }

    // 删除多个 使用, 英文逗号隔开
    async deletes(params) {
        const res = await Request.axiosInstance.post('/open/voice-type/delete', params)
        return res
    }

    async getDetail(params) {
        const res = await Request.axiosInstance.post('/open/voice-type/get', params)
        return res
    }


    //添加质检规则
    async addRule(params) {
        const res = await Request.axiosInstance.post('/open/voice-rule/add', params)
        return res
    }

    //编辑质检规则
    async editRule(params) {
        const res = await Request.axiosInstance.post('/open/voice-rule/edit', params)
        return res
    }

    //编辑质检规则
    async deleteRule(params) {
        const res = await Request.axiosInstance.post('/open/voice-rule/delete', params)
        return res
    }

    //质检规则列表
    async getRuleList(params) {
        const res = await Request.axiosInstance.post('/open/voice-rule/list', params)
        return res
    }
}

class VosService {
    async getList(params) {
        const res = await Request.axiosInstance.post('/open/voice-vos-types/list', params)
        return res
    }
    async getNameList(params) {
        const res = await Request.axiosInstance.post('/open/voice-vos-types/query-name', params)
        return res
    }

    async created(params) {
        const res = await Request.axiosInstance.post('/open/voice-vos-types/add', params)
        return res
    }

    async update(params) {
        const res = await Request.axiosInstance.post('/open/voice-vos-types/edit', params)
        return res
    }

    // 删除多个 使用, 英文逗号隔开
    async deletes(params) {
        const res = await Request.axiosInstance.post('/open/voice-vos-types/delete', params)
        return res
    }

    async getDetail(params) {
        const res = await Request.axiosInstance.post('/open/voice-vos-types/get', params)
        return res
    }


}

class ApiService {
    async getRecordList(params) {
        //  api质检记录列表
        const res = await Request.axiosInstance.post('/open/es-user-check/list', params)
        return res
    }
    async getRecordCustomColumnList() {
        //  api质检记录列表自定义列
        const res = await Request.axiosInstance.post('/web/user-options/get-option-key-value', {
            optionType: "voiceCheck",
            optionKey: "apiVoiceCheck"
        })
        return res
    }

    async getKeywordList(params) {
        //  api质检关键词列表
        const res = await Request.axiosInstance.post('/open/voice-api-types/list', params)
        return res
    }
    async getKeywordNameList(params) {
        //  api质检关键词列表（仅包含名字字段）
        const res = await Request.axiosInstance.post('/open/voice-api-types/query-name', params)
        return res
    }

    async addKeyword(params) {
        //  api质检关键词新增
        const res = await Request.axiosInstance.post('/open/voice-api-types/add', params)
        return res
    }

    async updateKeyword(params) {
        //  api质检关键词更新
        const res = await Request.axiosInstance.post('/open/voice-api-types/edit', params)
        return res
    }

    // 删除多个 使用, 英文逗号隔开
    async deleteKeywords(params) {
        //  api质检关键词删除
        const res = await Request.axiosInstance.post('/open/voice-api-types/delete', params)
        return res
    }

    async getKeywordDetail(params) {
        //  api质检关键词详情
        const res = await Request.axiosInstance.post('/open/voice-api-types/get', params)
        return res
    }


}

class StatisticsService {

}

class ManageService {
    //系统管理员查询用户配置
    /* 
        "page":1,           #当前页
        "pageSize":10,      # 每页参数
        "uid":100008,       # 用户id
        "amountId":9,       # 服务商id 
        "status":1,         # 状态
        "company":"duofang" # 企业名称(模糊)
    */
    async getUserBigOptions(params) {
        const res = await Request.axiosInstance.post('/open/voice-user-options/list', params)
        return res.data
    }

    // 线路质检总开关查询
    /**
     * "optionType":"voiceCheck",  # 固定参数,写死即可
     * "optionKey":"interfaceEnable" # 固定参数,写死即可
     * @param params
     * @returns
     */
    async getAllValue(params) {
        const res = await Request.axiosInstance.post('/web/user-options/get-option-key-value', params)
        return res
    }

    // 线路质检总开关更改状态
    async updateEdit(params) {
        const res = await Request.axiosInstance.post('/web/user-options/edit', params)
        return res
    }

    async updateData(params) {
        const res = await Request.axiosInstance.post('/open/voice-user-options/save-or-update', params)
        return res
    }
}

//质检工单
class WorkOrderService {
    async getRecordList(params) {
        //  质检工单列表
        //params:{
        //     "page": 1,
        //     "pageSize": 10,
        //     "uid": 100001,//渠道用户ID
        //     "amountId": 1,//服务商ID 就是线路ID
        //     "status": 3,//   工单状态: 3-待报备 6-待审批 9-待通知 12-待申诉 15-申诉待审批 18-已完结
        //     "orderNo": "",// 工单号
        //     "callId": "",// 通话ID
        //     "caller": "16287874432",//主叫
        //     "callee": "19733891931",//被叫
        //     "orderFrom": 0,//来源 0-平台质检 1-运营商质检
        //     "approval": 1,//审核通过 0-不通过 1-通过
        //     "createTimeBegin": "",//创建时间开始 类似 2023-01-01 00:00:00
        //     "createTimeEnd": ""//创建时间结束 类似 2023-01-01 00:00:00
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/list', params)
        return res
    }
    async getRecordDetail(params) {
        //  质检工单详情
        // params:{id:'工单ID'}
        const res = await Request.axiosInstance.post('/open/voice-order/get', params)
        return res
    }

    async getInfractionCount(params) {
        //  违规次数
        // params:{
        //     "caller":"18856283218",//主叫
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/how-many-times', params)
        return res
    }
    async getReportTemplate(params) {
        //  获取报备模板
        // params:{
        //     "id":"1",//工单ID
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/report-template', params)
        return res
    }

    async addWorkOrder(params) {
        //  从质检记录列表快速创建工单 调用该接口后，工单状态变为3（待报备）
        // params:{
        //     "checkId":"1640658445194059777",
        //     "violDesc":"违规说明的一段文字",
        //     "punishPropType":"0",
        //     "punishPropVal":"",
        //     "oprPenalties":"",
        //     "infractions":1,
        //     "orderFrom":0
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/add-from-check', params)
        return res
    }

    async editWorkOrder(params) {
        //  更新工单 工单状态为6之前可以调用
        // params:{
        //     "checkId":"1640658445194059777",
        //     "violDesc":"违规说明的一段文字",
        //     "punishPropType":"0",
        //     "punishPropVal":"",
        //     "oprPenalties":"",
        //     "infractions":1,
        //     "orderFrom":0
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/edit', params)
        return res
    }

    async addWorkOrderManually(params) {
        //  手动创建工单 调用该接口后，工单状态变为3（待报备）
        // params:{
        //     "checkId":"1640658445194059777",
        //     "amountId":"服务商ID 线路ID",
        //     "callId":"通话ID",
        //     "caller":"主叫",
        //     "callee":"被叫",
        //     "violDesc":"违规说明的一段文字",
        //     "punishPropType":"0",
        //     "punishPropVal":"",
        //     "oprPenalties":"",
        //     "infractions":1,
        //     "orderFrom":0
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/add', params)
        return res
    }
    async report(params) {
        //  报备上游 调用该接口后，工单状态变为6（待审批）
        // params:{
        //     "id": "1",
        //     "reportDesc": "报备内容的一段文字"
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/report', params)
        return res
    }
    async approval(params) {
        //  审核 调用该接口后，工单状态变为9（待通知）
        // params:{
        //     "id": "1",
        //     "approval": 1,
        //     "punishConfType": 1,
        //     "punishConfVal": 500,
        //     "disapprovalReason": "禁用原因说明"
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/approval', params)
        return res
    }
    async notice(params) {
        //  通知 调用该接口后，工单状态变为12（待申诉）
        // params:{
        //     "id": "1",
        //     "noticeType": 0,
        //     "noticeDesc": "通知内容的一段文字"
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/notice', params)
        return res
    }
    async appeal(params) {
        //  申诉 调用该接口后，工单状态变为15（申诉待审批）
        // params:{
        //     "id": "1",
        //     "appealContent": "申诉内容的一段文字",
        //     "appealAttachment": "http://agent.duofangtongxin.com/image1.jpg,http://agent.duofangtongxin.com/image2.jpg"
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/appeal', params)
        return res
    }
    async invalidate(params) {
        //  作废 调用该接口后，工单状态变为17（已作废）
        // params:{
        //     "id": "1",
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/do-void', params)
        return res
    }
    async complete(params) {
        //  工单完结 调用该接口后，工单状态变为18（已完结）
        // params:{
        //     "id": "1",
        //     "execType": 0,
        //     "punishFinalType": 1,
        //     "punishFinalVal": 500
        // }
        const res = await Request.axiosInstance.post('/open/voice-order/complete', params)
        return res
    }

}
export default {
    _recordService: new RecordService(),
    _ruleService: new RuleService(),
    _vosService: new VosService(),
    _apiService: new ApiService(),
    _workOrderService: new WorkOrderService(),
    _statisticsService: new StatisticsService(),
    _manageService: new ManageService()
}